<template>
  <div :style="this.CSS">
    <el-checkbox-group
      @change="$emit('input', localModel)"
      :disabled="readonly"
      :name="name"
      :size="size"
      v-model="localModel"
    >
        <el-checkbox
          v-for="(item, index) in options"
          :key="index"
          :label="item.value"
          :is="type === 'basic' ? 'el-checkbox' : 'el-checkbox-button'"
        >{{ item.name }}
      </el-checkbox>
    </el-checkbox-group>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
export default {
  name: 'a-checkbox',
  mixins: [mixin],
  props: {
    model: {
      frozen: true
    },
    name: {
      type: String,
      description: 'Атрибут'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    type: {
      type: String,
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },
    size: {
      type: String,
      description: 'Размер'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    options: {
      type: Array,
      default () {
        return [{ name: 'test', value: 'test' }]
      },
      editor: 'Options'
    }
  },
  computed: {
  },
  data () {
    return {
      localModel: this.$attrs.value || []
    }
  },
  mounted () {
    // this.$emit('input', this.localModel)
  }
}
</script>

<style lang="scss" scoped>

</style>
